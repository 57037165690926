$brand-color-sibur: #008B94;

.vue-slider-process {
  background-color: $brand-color-sibur;
}

.vue-slider-dot-handle-focus {
  box-shadow: 0 0 1px 2px rgba($brand-color-sibur, 0.36);
}

.vue-slider-rail {
  background-color: #D4EDED;
}

.vue-slider-dot-handle {
  background-color: $brand-color-sibur;
}

.vue-slider-dot-tooltip-inner {
  border-color: $brand-color-sibur;
  background-color: $brand-color-sibur;
}

.vue-slider-dot-handle {
  box-shadow: 0 0 1px 2px rgba($brand-color-sibur, 0.36);
}
