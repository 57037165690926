.toast-container {
  & > div {
    opacity: 1 !important;
  }

  & > div.toast {
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2) !important;
  }

  & > div.toast-notify {
    padding: 15px !important;
    background-color: #323232 !important;
  }
}
