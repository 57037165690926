.app-header {
  padding: 0 1rem;
  box-shadow: 0 .175rem .25rem rgba(0,0,0,.1);

  &.navbar {
    flex-wrap: nowrap;
  }

  .navbar-toggler {
    margin-right: 0 !important;
    min-width: 40px;
  }

  .navbar-brand {
    width: auto;
    padding: 0 5px;
  }

  .nav-item .nav-link > .img-avatar,
  .nav-item .avatar.nav-link > img {
    width: 35px;
    object-fit: cover;
  }

  .dropdown-item {
    min-width: 100px;
  }
}

.main {
  /*height: calc(100vh - 55px);
  overflow: auto;*/
}

@include media-breakpoint-down(md) {
  .app-header {
    .navbar-brand {
      position: static;
      top: auto;
      left: auto;
      margin-left: 0;
    }
  }
}

@include media-breakpoint-up(md) {
  .brand-minimized .app-header .navbar-brand {
    width: auto;
  }
}

.sidebar .nav-link .nav-icon {
  color: #fff;
  font-size: 1.125rem;
}

.sidebar-fixed.sidebar-minimized .app-header + .app-body .sidebar {
  overflow: visible;
}

.app-header + .app-body .sidebar {
  overflow: hidden;
}

.sidebar-minimizer {
  width: 220px;
}

body:not(.sidebar-minimized) .sidebar .nav-link {
  display: flex;
  align-items: center;
}

.sidebar-minimized .sidebar section :not(.nav-dropdown-items) > .nav-item:last-child::after {
  display: none;
}

.app-header, .app-footer, .sidebar, .main, .aside-menu {
  transition: margin-left 0.25s, width 0.25s, flex 0.25s;
}
