.dropdown-item {
  padding: 8px 20px;
}

.dropdown-item i {
  color: inherit;
  width: auto;
  text-align: left;
}

.dropdown-header {
  background: inherit;
  border-bottom: none;
}

.dropdown-divider {
  margin: 0;
}

.btn-group-xs > .btn, .btn-xs {
  padding: 1px 5px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px;
}

.btn.btn-without {
  box-shadow: none;

  &.active:focus {
    box-shadow: none;
  }
}

.form-control.is-valid {
  border-color: #e4e7ea;
}

.form-control.is-valid:focus {
  color: #5c6873;
  background-color: #fff;
  border-color: #8ad4ee;
  outline: 0;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 0.2rem rgba(32, 168, 216, 0.25);
}

.modal--fullscreen {
  padding-left: 0 !important;

  .modal {
    padding: 0 !important;
  }

  .modal-dialog {
    max-width: 100% !important;
    height: 100%;
    margin: 0;
  }

  .modal-content {
    border: 0;
    border-radius: 0;
    min-height: 100%;
    height: auto;
  }

  .modal__close {
    position: absolute;
    display: block;
    width: 3rem;
    height: 3rem;
    right: 0;
    top: 0;
    font-size: 4rem;
    line-height: 3rem;
    font-weight: 700;
    opacity: .75;
    transition: opacity .2s;
    cursor: pointer;
    text-align: center;

    &:hover {
      color: #000;
      text-decoration: none;
    }
  }
}

.modal--without-header {
  .modal-header {
    display: none;
  }
}

.modal--without-footer {
  .modal-footer {
    display: none;
  }
}

.modal-v-center {
  .modal-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.modal-body {
  min-height: 50px;
}

.modal-footer {
  flex-wrap: wrap;
  margin-bottom: -5px;

  & > * {
    margin-bottom: 5px;
  }
}

.modal--card-preview-method {
  .modal-dialog {
    max-width: 990px;
  }
}

@include media-breakpoint-up(md) {
  .modal-dialog.modal-md {
    max-width: 600px;
  }
}

.card-body {
  position: relative;
}

.tooltip.b-tooltip {
  .tooltip-inner {
    max-width: 300px;
  }
}

@include media-breakpoint-up(md) {
  .tooltip.b-tooltip {
    .tooltip-inner {
      max-width: 400px;
    }
  }
}
