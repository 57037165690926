.multiselect__input,
.multiselect__single {
  font-size: 0.9rem;
  padding: 0;
}

.multiselect__option {
  font-size: 0.9rem;
}

.multiselect__placeholder {
  padding-top: 0;
  margin-bottom: 0;
  font-size: 0.9rem;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
}

.multiselect {
  //min-height: calc(1.5em + 0.75rem + 2px);
}

.multiselect__tags {
  //min-height: calc(1.5em + 0.75rem + 2px);
}

.multiselect__no-mline {
  .multiselect__tags-wrap {
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    max-width: 100%;
    text-overflow: ellipsis;
  }
}
