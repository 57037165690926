@import "../bootstrap-variables";
//@import '~bootstrap/scss/bootstrap';
@import '~bootstrap-vue/dist/bootstrap-vue.css';
@import "~element-ui/lib/theme-chalk/index.css";
//@import "~font-awesome/css/font-awesome.css";
@import "~medium-editor/dist/css/medium-editor.css";
@import "~medium-editor/dist/css/themes/default.css";
@import "~vue-select/src/scss/vue-select.scss";
@import "~vue-multiselect/dist/vue-multiselect.min.css";
@import "~@coreui/coreui/scss/coreui";
@import "~@coreui/icons/css/coreui-icons.min.css";
@import "~simple-line-icons/scss/simple-line-icons.scss";
@import "~swiper/css/swiper.min.css";
@import "~vue-toastr/src/vue-toastr";
@import "~dragula/dist/dragula.min.css";
@import "~vue-datetime/dist/vue-datetime.css";
@import "~nprogress/nprogress.css";
@import '~vue-slider-component/theme/default.css';

@import "coreui";
@import "bootstrap";
@import "mx-datepicker";
@import "multiselect";
@import "toast";
@import "vue-slider-component";

.multiselect__placeholder {
  margin-bottom: 6px !important;
}
