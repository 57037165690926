@import "vendor/index";

.medium-editor-element {
  min-height: 1.5em;
  outline: none;
}

.medium-editor-element {
  *[style] {
    font-size: inherit !important;
    line-height: inherit !important;
  }
}

.fa, .fas {
  font-weight: 900 !important;
}

.no-100 {
  width: auto !important;
}

div.mv-select {
  &.v-select {
    height: auto;
  }

  .v-dropdown-item {
    padding: 0.5rem 0.5rem;
  }

  .v-select-toggle {
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    height: calc(1.5em + 0.75rem);
  }

  .v-dropdown-container {
    padding: 0;
  }
}

.popover-0 {
  .popover-body {
    padding: 0;
  }
}


